<h1 mat-dialog-title class="text-center" fxLayoutAlign="center center">Assign Trailer Clip</h1>
<div mat-dialog-content>
  <ngx-file-drop
    class="ngx-file-drop"
    dropZoneLabel="Drop files here"
    (onFileDrop)="dropped($event)"
    (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)"
  >
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
      <div fxLayout="column" fxLayoutAlign="center center">
        <mat-icon>cloud_upload</mat-icon>
        <div>Drag & Drop to Upload</div>
        <button mat-flat-button color="primary" class="mat-small" (click)="openFileSelector()">Browse Files</button>
      </div>
    </ng-template>
  </ngx-file-drop>

  <div *ngFor="let item of uploadChunkService.getProgressUpload() | async" [style.marginBottom]="'5px'">
    <div *ngIf="item.progress !== 100 && !item.error">
      {{ item.title }} <span [style.color]="'#125fab'">{{ item.progress }}%</span>
    </div>
    <mat-progress-bar
      *ngIf="item.progress !== 100 && !item.error"
      mode="determinate"
      [value]="item.progress"
      [style.height]="'10px'"
    >
    </mat-progress-bar>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" class="flex-p-y">
    <form (submit)="handleSearchVideos()">
      <mat-form-field appearance="outline" class="search-input">
        <input matInput [(ngModel)]="params.search" name="search" placeholder="Input your search" />
        <mat-icon type="submit" matSuffix (click)="handleSearchVideos()" class="cursor-pointer">search</mat-icon>
      </mat-form-field>
    </form>
  </div>
  <div [style.position]="'relative'">
    <app-spinner *ngIf="isLoading"></app-spinner>
    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="handleSortChange($event)">
      <ng-container matColumnDef="thumbnail">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thumbnail</th>
        <td mat-cell *matCellDef="let row">
          <img class="video-thumb" [src]="row?.thumbnail || 'assets/images/no-image.png'" [alt]="row?.title" />
        </td>
      </ng-container>
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Video Title</th>
        <td mat-cell *matCellDef="let row">
          {{ row.title || '_' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Video Description</th>
        <td mat-cell *matCellDef="let row">
          {{ row?.description || '_' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
        <td mat-cell *matCellDef="let row">{{ row?.duration }}</td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Added</th>
        <td mat-cell *matCellDef="let row">
          {{ row?.created ? (row.created | date: 'MM-dd-yyyy') : '_' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row" [style.padding]="'10px'" class="align-right">
          <button
            mat-flat-button
            class="mat-small"
            *ngIf="row?.job_status === 'COMPLETED'"
            color="primary"
            (click)="handleSelect(row)"
            [disabled]="activeVideoId === row.uuid"
          >
            SELECT
          </button>
          <button mat-flat-button class="mat-small" *ngIf="row?.job_status === 'ENCODING'" color="primary" disabled>
            ENCODING
            <mat-icon *ngIf="row?.job_status === 'ENCODING'"
              ><mat-spinner color="accent" diameter="20"> </mat-spinner
            ></mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">
          <app-empty [description]="'No Playlist'"></app-empty>
        </td>
      </tr>
    </table>
    <mat-paginator
      showFirstLastButtons
      [length]="params.total"
      [pageIndex]="params.page - 1"
      [pageSize]="params.pageSize"
      [pageSizeOptions]="params.pageSizeOptions"
      (page)="pageChanged($event)"
    >
    </mat-paginator>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="center center"></div>
