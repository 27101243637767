import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // private options = {
  //   headers: new HttpHeaders().set('Content-Type', 'application/json'),
  // };

  constructor(private httpClient: HttpClient) {}

  public get(path: string, params: HttpParams = new HttpParams(), headers = new HttpHeaders()): Observable<any> {
    return this.httpClient.get(path, { params, headers }).pipe(catchError(this.formatErrors));
  }

  public put(path: string, body: object = {}): Observable<any> {
    return this.httpClient.put(path, body).pipe(catchError(this.formatErrors));
  }

  public patch(path: string, body: object = {}): Observable<any> {
    return this.httpClient.patch(path, body).pipe(catchError(this.formatErrors));
  }

  public post(path: string, body: object = {}, options = {}): Observable<any> {
    return this.httpClient.post(path, body, options).pipe(catchError(this.formatErrors));
  }

  public delete(path: string): Observable<any> {
    return this.httpClient.delete(path).pipe(catchError(this.formatErrors));
  }

  public download(path: string): Observable<any> {
    return this.httpClient
      .get(path, {
        responseType: 'blob',
      })
      .pipe(catchError(this.formatErrors));
  }

  public uploadChunk(path: string, body: object = {}, headers = new HttpHeaders()) {
    return this.httpClient
      .put(path, body, {
        headers,
      })
      .pipe(catchError(this.formatErrors))
      .toPromise();
  }

  public uploadChunkQMS(path: string, body: object = {}) {
    return this.httpClient.post(path, body).pipe(catchError(this.formatErrors)).toPromise();
  }

  public formatErrors(error: any): Observable<any> {
    let errorMsg = '';

    if (error.error) {
      if (typeof error.error === 'string') {
        errorMsg = error.error;
      } else if (Object.keys(error.error).length > 0) {
        Object.values(error.error).forEach((errs) => {
          if (Array.isArray(errs)) {
            errorMsg += errs[0];
          } else {
            errorMsg += errs || '';
          }
        });
      }
    } else {
      if (typeof error === 'string') {
        errorMsg = error;
      }
    }

    errorMsg === errorMsg ? errorMsg : 'Something went wrong';
    return throwError(errorMsg);
  }
}
