import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { ChannelService, VideoPlaylistService } from '@app/shared/services';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { getDuration, getPlayingTime, getStreamId, getVideoPosition } from '@app/helpers/live.helper';
import { filter, retry } from 'rxjs/operators';
import { AuthService } from '@app/auth/services';
import { StreamType } from '@app/shared/domain';

@Component({
  selector: 'app-preview-channel-dialog',
  templateUrl: './preview-channel-dialog.component.html',
  styleUrls: ['./preview-channel-dialog.component.scss'],
})
export class PreviewChannelDialogComponent implements OnInit, OnDestroy {
  public activeChannel$ = this.channelService.getActiveChannel();

  channelId: string;
  public videoSrc: any;
  public playlistName: string;
  public videoName: string;
  public upNextVideo: string;
  public upNextTimeIn: any;
  public upNextTime: string;
  public title: string;
  isChannelOnline: boolean;
  subscription: Subscription[] = [];
  channel: any;
  nowPlaylist: any;
  plPlayingTime: number;
  plPosition: number;
  loadingPlaylist: boolean;
  currentPlaylistId: string;
  streamId: string;

  private channelSocket: WebSocketSubject<any>;
  private retrievePlaying: any;

  constructor(
    private channelService: ChannelService,
    public dialogRef: MatDialogRef<PreviewChannelDialogComponent>,
    private readonly videoPlaylistService: VideoPlaylistService,
    private readonly authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: PreviewChannelDialogComponentModel
  ) {
    this.channelId = data.channelId;
    this.title = data.title;
  }
  ngOnDestroy(): void {
    if (this.subscription.length) {
      for (const subs of this.subscription) {
        subs.unsubscribe();
      }
    }
    if (this.upNextTimeIn) {
      clearInterval(this.upNextTimeIn);
    }

    if (this.channelSocket) {
      this.channelSocket.complete();
      clearInterval(this.retrievePlaying);
    }
  }

  ngOnInit(): void {
    const cleanUpPlaylist = () => {
      clearInterval(this.upNextTimeIn);
      this.playlistName = undefined;
      this.upNextVideo = undefined;
      this.nowPlaylist = undefined;
      this.currentPlaylistId = undefined;
    };
    if (this.channelId) {
      if (this.channelSocket) {
        this.channelSocket.complete();
        clearInterval(this.retrievePlaying);
      }
      this.handleSelectChannel(this.channelId);
      const token = this.authService.getStoreToken();
      const tenant = this.authService.getTenant();
      this.channelSocket = webSocket({
        url: `${environment.wsUrl}/tenant/${tenant}/?token-key=${token}`,
      });
      const subId = new Date().getTime();
      this.retrievePlaying = setInterval(() => {
        this.channelSocket.next({
          action: 'retrieve_playing_index',
          request_id: subId,
          channel_id: this.channelId,
        });
      }, 1000);
      this.channelSocket.pipe(retry()).subscribe(
        ({ data }) => {
          if (data && data.playlist_status) {
            this.isChannelOnline = data.rtmp_status === 'broadcasting';

            if (!this.isChannelOnline && data.playlist_status === 'finished') {
              this.streamId = undefined;
              cleanUpPlaylist();
              return;
            }

            if (this.isChannelOnline) {
              this.streamId = getStreamId(this.channelId, StreamType.BROADCASTING);
              cleanUpPlaylist();
              return;
            }

            if (!this.nowPlaylist) {
              this.handleLoadPlaylist(this.currentPlaylistId);
              return;
            }

            this.plPlayingTime = data.total_sec_passed;
            this.plPosition = data.current_play_index;

            if (this.nowPlaylist) {
              this.handlePlaylistTimeline(this.nowPlaylist);
            }
          }
        },
        (err) => console.log(err),
        () => console.log('complete')
      );
    }
  }

  setStreamStatus(isPlaying: boolean) {
    if (isPlaying && !this.nowPlaylist) {
      this.handleLoadPlaylist(this.currentPlaylistId);
    }
  }

  handleSelectChannel(channelId: string) {
    this.channelService.getChannel(channelId).subscribe(
      (channel) => {
        const { current_playlist_uuid } = channel;
        this.channel = channel;

        if (channel) {
          if (channel.status === 'online') {
            this.streamId = getStreamId(channelId, StreamType.BROADCASTING);
          } else {
            this.currentPlaylistId = current_playlist_uuid;
            this.streamId = getStreamId(channelId, StreamType.PLAYLIST);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  handlePlaylistTimeline(livePlaylist: any) {
    if (!this.plPlayingTime) {
      return;
    }
    const { videos, title } = livePlaylist;
    const { vPosition, remainTime } = getVideoPosition(videos, this.plPlayingTime); //this.plPosition;
    if (!videos[vPosition]) {
      return;
    }
    const playingVideo = videos[vPosition].video;
    this.playlistName = title;
    this.videoName = playingVideo.title;
    this.upNextVideo = vPosition + 1 >= videos.length ? videos[0].video.title : videos[vPosition + 1].video.title;
    this.isChannelOnline = false;

    let time = remainTime;

    this.upNextTime = CommonHelpers.secondToDuration(time);
    let nextPos = vPosition + 1;
    nextPos = nextPos >= videos.length ? 0 : nextPos;
    if (this.upNextTimeIn) {
      clearInterval(this.upNextTimeIn);
    }
    this.upNextTimeIn = setInterval(() => {
      time -= 1;
      if (time < 0) {
        time = 0;
      }
      if (time === 0) {
        const currentVideo = videos[nextPos].video;
        this.videoName = currentVideo.title;
        nextPos++;
        nextPos = nextPos >= videos.length ? 0 : nextPos;
        const nextVideo = videos[nextPos].video;
        this.upNextVideo = nextVideo.title;
        time = getDuration(currentVideo.duration).asSeconds();
      }
      this.upNextTime = CommonHelpers.secondToDuration(time);
    }, 1000);
  }

  handleLoadPlaylist(playlist_uuid?: string) {
    if (!playlist_uuid) {
      return;
    }
    this.loadingPlaylist = true;
    this.videoPlaylistService.getClientPlaylistById(playlist_uuid).subscribe((livePlaylist) => {
      this.nowPlaylist = livePlaylist;
      this.loadingPlaylist = false;
      this.handlePlaylistTimeline(livePlaylist);
    });
  }
}

export class PreviewChannelDialogComponentModel {
  constructor(public channelId: string, public title: string) {}
}
