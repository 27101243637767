import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly activeProjectSubject$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  private readonly projectsSubject$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  selectedProjectListSubject$ = new BehaviorSubject(1);

  constructor(private apiService: ApiService) {}

  setIsLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  getIsLoading() {
    return this.isLoading$.asObservable().pipe(distinctUntilChanged());
  }

  setActiveProject(project: { name: string; uuid: string; description?: string }) {
    this.activeProjectSubject$.next(project);
  }

  getActiveProject() {
    return this.activeProjectSubject$.asObservable().pipe(distinctUntilChanged());
  }

  addNewProjects(project: { name: string; uuid: string; description?: string }) {
    this.projectsSubject$.next(project);
  }

  getNewProjects() {
    return this.projectsSubject$.asObservable().pipe(distinctUntilChanged());
  }

  getProjectList(): Observable<any> {
    return this.apiService.get(`/api/v1/video-editor/`);
  }

  createProject(data: { name: string; description?: string }): Observable<any> {
    return this.apiService.post(`/api/v1/video-editor/create-project/`, data);
  }

  deleteProject(uuid: string): Observable<any> {
    return this.apiService.delete(`/api/v1/video-editor/${uuid}`);
  }

  addVideoToTheProject(
    data: {
      uuid: string;
      layer?: number;
      position?: number;
    },
    uuid: string
  ) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/add-video/`, data);
  }

  removeVideoFromTheProject(data: { uuid: string }, uuid: string) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/delete-video/`, data);
  }

  mergeProject(uuid: string) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/merge-project/`);
  }

  getProjectVideoList(uuid: string) {
    return this.apiService.get(`/api/v1/video-editor/${uuid}/list-video/`);
  }

  reorderProjectVideoList(data: { old_position: number; new_position: number }, uuid: string) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/reorder-video/`, data);
  }

  cutTheVideo(data: { start?: number; end?: number; uuid?: string }, uuid: string) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/cut-video/`, data);
  }

  copyTheVideo(data: { start?: number; end?: number; uuid?: string }, uuid: string) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/copy-video/`, data);
  }

  pasteTheVideo(
    data: {
      name?: string;
      url?: string;
      layer?: number;
      position?: number;
      uuid?: string;
    },
    uuid: string
  ) {
    return this.apiService.post(`/api/v1/video-editor/${uuid}/paste-video/`, data);
  }

  undoProjectChanges(uuid: string) {
    return this.apiService.post(`/video-editor/${uuid}/undo/`);
  }

  redoProjectChanges(uuid: string) {
    return this.apiService.post(`/video-editor/${uuid}/redo/`);
  }
}
