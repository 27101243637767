<ng-container *ngIf="channelLoading$ | async as channelLoading; else noChannelLoading">
  <button mat-button [color]="color" [class]="buttonClass" [loading]="channelLoading">Loading Channel</button>
</ng-container>
<ng-template #noChannelLoading>
  <ng-container *ngIf="channelList$ | async as channelList; else noChannelList">
    <button mat-button [color]="color" [class]="buttonClass" [matMenuTriggerFor]="menu">
      <ng-container *ngIf="activeChannel$ | async as activeChannel; else noActiveChannel">
        {{ activeChannel.title }}
      </ng-container>
      <ng-template #noActiveChannel> No Active Channel </ng-template>
      <mat-icon *ngIf="channelList.length > 1">expand_more</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngIf="channelList.length; else tplEmpty">
        <ng-container *ngFor="let channel of channelList">
          <button
            (click)="changeActiveChannel(channel)"
            *ngIf="channel.uuid !== (activeChannel$ | async)?.uuid"
            mat-menu-item
          >
            <mat-icon>swap_horiz</mat-icon>
            <span>Switch to {{ channel.title }}</span>
          </button>
        </ng-container>
      </ng-container>
      <ng-template #tplEmpty>
        <button mat-menu-item>No chanel available!</button>
      </ng-template>
      <button mat-menu-item (click)="handleCreateChannel()">
        <mat-icon>add_circle</mat-icon>
        <span>Create New Channel</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-template>
<ng-template #noChannelList>
  <button mat-button [color]="color" [class]="buttonClass">No Channel</button>
</ng-template>
