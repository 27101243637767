<div fxLayout="column" fxLayoutAlign="center center" [style.color]="color" class="footer" *ngIf="showFooter">
  <div>Copyright © {{ year }} TvStartup Inc.</div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <div>Privacy Policy</div>
    <mat-divider
      [style.border-right-color]="color"
      vertical
      [style.height.px]="12"
      [style.margin-right.px]="8"
      [style.margin-left.px]="8"
    ></mat-divider>
    <div>Support</div>
  </div>
</div>
