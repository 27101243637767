import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { Audio } from '@app/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  private readonly isLoadingTimeLine$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoadingVodPlaylist$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoadingVodCategory$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isLoadingAudioSettings$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setIsLoadingAudioSettings(isLoading: boolean) {
    this.isLoadingAudioSettings$.next(isLoading);
  }

  getIsLoadingAudioSettings() {
    return this.isLoadingAudioSettings$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingTimeLine(isLoading: boolean) {
    this.isLoadingTimeLine$.next(isLoading);
  }

  getIsLoadingTimeLine() {
    return this.isLoadingTimeLine$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingVodPlaylist(isLoading: boolean) {
    this.isLoadingVodPlaylist$.next(isLoading);
  }

  getIsLoadingVodPlaylist() {
    return this.isLoadingVodPlaylist$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoadingVodCategory(isLoading: boolean) {
    this.isLoadingVodCategory$.next(isLoading);
  }

  getIsLoadingVodCategory() {
    return this.isLoadingVodCategory$.asObservable().pipe(distinctUntilChanged());
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading$.next(isLoading);
  }

  getIsLoading() {
    return this.isLoading$.asObservable().pipe(distinctUntilChanged());
  }

  constructor(private apiService: ApiService) {}

  getAudios(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/audios/', params);
  }

  getAudio(audioId: string): Observable<any> {
    return this.apiService.get(`/api/v1/audios/${audioId}`);
  }

  updateAudio(audio: Audio, audioId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/audios/${audioId}/`, audio);
  }

  updateAudioThumbnail(fmData: FormData, audioId: string): Observable<any> {
    return this.apiService.put(`/api/v1/audios/${audioId}/update-audio-thumbnail/`, fmData);
  }

  deleteAudio(audioId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/audios/${audioId}/`);
  }

  updateAudioImage(fmData: FormData, audioId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/audios/${audioId}/upload-audio-image/`, fmData);
  }

  removeAudioBanner(audioId: string): Observable<any> {
    return this.apiService.put(`/api/v1/audios/${audioId}/remove-audio-banner/`);
  }

  removeAudioPoster(audioId: string): Observable<any> {
    return this.apiService.put(`/api/v1/audios/${audioId}/remove-audio-poster/`);
  }

  removeAudioThumnail(audioId: string): Observable<any> {
    return this.apiService.put(`/api/v1/audios/${audioId}/remove-audio-thumbnail/`);
  }

  removeAudioSubtitle(audioId: string): Observable<any> {
    return this.apiService.put(`/api/v1/audios/${audioId}/remove-audio-subtitle/`);
  }

  attachmentFiles(fmData: FormData, audioId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audios/${audioId}/save-attach-file/`, fmData);
  }

  updateAudioSeo(data: any, audioId: string): Observable<any> {
    return this.apiService.post(`/api/v1/audios/${audioId}/seo/`, data);
  }

  uploadAudio(fmData: FormData): Observable<Audio> {
    return this.apiService.post('/api/v1/audio-library/upload-audio-file/', fmData);
  }

  replaceAudio(audioId: string, data: FormData): Observable<Audio> {
    return this.apiService.put(`/api/v1/audios/${audioId}/replace-audio-file/`, data);
  }
}
