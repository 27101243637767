import { TutorialType } from '@app/app.constants';
import { environment } from '@env/environment';

export const Tutorials = [
  {
    id: 1,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/START HERE DASHBOARD OVERVIEW.png`,
    title: 'START HERE: DASHBOARD OVERVIEW',
    description: `Start with this tutorial first if your account is newly created. This video provides an overview of your dashboard so you can get a feel for how the software works and how to get started with your new account. This video also explains how to send our team your project details if you have ordered apps along with your account.`,
    source: `${environment.staticCDNUrl}/video-tutorials/START HERE- DASHBOARD OVERVIEW.mp4`,
    watchStatus: 1,
    duration: 194,
    tags: [TutorialType.Dashboard],
  },
  {
    id: 2,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO UPLOAD VIDEOS.png`,
    title: 'HOW TO UPLOAD VIDEOS',
    description: `This video tutorial explains how to upload videos to your dashboard from your computer. As described in the tutorial, the recommended video format for videos you upload to your channel is as follows: .MP4 or .MOV format, around 4.0 MBPS (4,000 KBPS) in bitrate (or higher) and 1920x1080 HD resolution for most accounts. 

        The same principles from this video apply to uploading audio content as well, and recommended audio formats are: .MP3 and .WAV audio files.

        Here is the link to our Format Factory tutorial which explains how to easily convert your videos if needed ahead of uploading them: 

        Format Factory download link: http://www.pcfreetime.com/formatfactory/index.php?language=en
        `,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO UPLOAD VIDEOS.mp4`,
    watchStatus: 0,
    duration: 204,
    tags: [TutorialType.VideoLibrary],
  },
  {
    id: 3,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO SETUP APP SETTINGS.png`,
    title: 'HOW TO SETUP APP SETTINGS',
    description:
      'In this video tutorial we show how to configure the App Settings section with your dashboard. These settings control several important settings for the way your mobile and/or TV channel apps function and also allows you to setup and edit information that displays within the settings tabs of your apps.',
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO SETUP APP SETTINGS.mp4`,
    watchStatus: 0,
    duration: 320,
    tags: [TutorialType.AppSettings],
  },
  {
    id: 4,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/VIDEO - AUDIO LIBRARY INTRODUCTION.png`,
    title: 'VIDEO/AUDIO LIBRARY INTRODUCTION',
    description:
      'Watch a brief introduction to the video and audio libraries within your account where you will manage the content on your channel. Then watch the next tutorials for a detailed look at using the video settings and/or audio settings for each clip in your library.',
    source: `${environment.staticCDNUrl}/video-tutorials/VIDEO - AUDIO LIBRARY INTRODUCTION.mp4`,
    watchStatus: 0,
    duration: 190,
    tags: [TutorialType.VideoLibrary, TutorialType.AudioLibrary],
  },
  {
    id: 5,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE VIDEO SETTINGS.png`,
    title: 'HOW TO USE VIDEO SETTINGS',
    description:
      'This tutorial shows you how to use the video settings for clips within your library. We explain each feature available under video settings section and how to apply them to your videos for different purposes.',
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE VIDEO SETTINGS.mp4`,
    watchStatus: 0,
    duration: 406,
    tags: [TutorialType.VideoLibrary],
  },
  {
    id: 6,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE AUDIO SETTINGS.png`,
    title: 'HOW TO USE AUDIO SETTINGS',
    description:
      'Inside this video tutorial we explain all the options for audio clip settings within your audio library. Note that access to our audio features are only available in certain plans and may require purchasing an add-on to your account to access them.',
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE AUDIO SETTINGS.mp4`,
    watchStatus: 0,
    duration: 172,
    tags: [TutorialType.AudioLibrary],
  },
  {
    id: 7,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/VIDEO ON DEMAND INTRODUCTION.png`,
    title: 'VIDEO ON DEMAND INTRODUCTION',
    description:
      'Watch this tutorial to learn everything you need to know about creating and managing the video on demand categories inside your channel. We cover all the basics and more in this video.',
    source: `${environment.staticCDNUrl}/video-tutorials/VIDEO ON DEMAND INTRODUCTION.mp4`,
    watchStatus: 0,
    duration: 288,
    tags: [TutorialType.VideoOnDemand],
  },
  {
    id: 8,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/VIDEO ON DEMAND PLAYLISTS TUTORIAL.png`,
    title: 'VIDEO ON DEMAND PLAYLISTS TUTORIAL',
    description:
      'Wondering how to use the video on demand playlists feature? This is different from the regular streaming playlists feature on your channel. It’s a handy way to combine multiple clips into a single VOD clip, for example, to place an ad at the start of your video before it plays. See how it works inside this tutorial.',
    source: `${environment.staticCDNUrl}/video-tutorials/VIDEO ON DEMAND PLAYLISTS TUTORIAL.mp4`,
    watchStatus: 0,
    duration: 195,
    tags: [TutorialType.VideoOnDemand, TutorialType.VideoPlaylist],
  },
  {
    id: 9,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO CREATE AND MANAGE PLAYLISTS.png`,
    title: 'HOW TO CREATE AND MANAGE PLAYLISTS ',
    description:
      'This tutorial explains how to create, edit, schedule and manage video and/or audio playlists within your TvStartup dashboard. It also covers how to view and use the playlist queue to monitor the status and schedule of your playlists.',
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO CREATE AND MANAGE PLAYLISTS.mp4`,
    watchStatus: 0,
    duration: 262,
    tags: [TutorialType.VideoOnDemand, TutorialType.VideoPlaylist, TutorialType.AudioPlaylist],
  },
  {
    id: 10,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/SUBSCRIPTIONS INTRODUCTION.png`,
    title: 'SUBSCRIPTIONS INTRODUCTION',
    description: `Start here to learn how to use subscriptions on your channel. In this tutorial we provide an introduction to subscriptions and explain each option available inside the section. 

        a. HOW TO CREATE AND APPLY SUBSCRIPTION PLANS
          This tutorial shows how to create subscription plans, edit them, and apply them to your content in your channel so you can charge for access to your videos or audio content.

        b. HOW TO MANAGE SUBSCRIBERS
          See how to manage the subscriber accounts on your channel.

        c. PAYMENT GATEWAYS, PAYMENT HISTORY, COUPONS
          This video shows how to connect and use your payment gateway accounts, manage your payment history for each transaction made by your subscribers, and how to use coupon codes.
      `,
    source: `${environment.staticCDNUrl}/video-tutorials/SUBSCRIPTIONS INTRODUCTION.mp4`,
    watchStatus: 0,
    duration: 120,
    tags: [TutorialType.Subscription],
  },
  {
    id: 11,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO CREATE AND APPLY SUBSCRIPTION PLANS.png`,
    title: 'HOW TO CREATE AND APPLY SUBSCRIPTION PLANS',
    description: `This tutorial shows how to create subscription plans, edit them, and apply them to your content in your channel so you can charge for access to your videos or audio content.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO CREATE AND APPLY SUBSCRIPTION PLANS.mp4`,
    watchStatus: 0,
    duration: 321,
    tags: [TutorialType.Subscription],
  },
  {
    id: 12,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO MANAGE SUBSCRIBERS.png`,
    title: 'HOW TO MANAGE SUBSCRIBERS',
    description: `See how to manage the subscriber accounts on your channel.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO MANAGE SUBSCRIBERS.mp4`,
    watchStatus: 0,
    duration: 121,
    tags: [TutorialType.Subscription],
  },
  {
    id: 13,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/PAYMENT GATEWAYS, PAYMENT HISTORY, COUPONS.png`,
    title: 'PAYMENT GATEWAYS, PAYMENT HISTORY, COUPONS',
    description: `This video shows how to connect and use your payment gateway accounts, manage your payment history for each transaction made by your subscribers, and how to use coupon codes.`,
    source: `${environment.staticCDNUrl}/video-tutorials/PAYMENT GATEWAYS, PAYMENT HISTORY, COUPONS.mp4`,
    watchStatus: 0,
    duration: 211,
    tags: [TutorialType.Subscription],
  },
  {
    id: 14,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE THE RESTREAMER.png`,
    title: 'HOW TO USE THE RESTREAMER',
    description: `This tutorial explains how to link your social media accounts to re-stream your playlist or live broadcast streams directly to your accounts using the built-in tool within our dashboard.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE THE RESTREAMER.mp4`,
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.Restream],
  },
  {
    id: 15,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE THE LIVE RECORDER.png`,
    title: 'HOW TO USE THE LIVE RECORDER',
    description: `In this tutorial, we demonstrate how to use the Live Recorder feature to record your live broadcasts and archive them for future use.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE THE LIVE RECORDER.mp4`,
    watchStatus: 0,
    duration: 139,
    tags: [TutorialType.LiveRecorder],
  },
  {
    id: 16,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE PAY-PER-VIEW.png`,
    title: 'HOW TO USE PAY-PER-VIEW',
    description: `Watch this video tutorial to learn everything you need to know about using the Pay-Per-View (PPV) features within your TvStartup dashboard, so you can charge a one-time price to access your video on-demand content or your live streams.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO USE PAY-PER-VIEW.mp4`,
    watchStatus: 0,
    duration: 278,
    tags: [TutorialType.PPV],
  },
  {
    id: 17,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO BROADCAST LIVE (SIMPLE LIVESTREAM).png`,
    title: 'HOW TO BROADCAST LIVE (SIMPLE LIVESTREAM)',
    description: `Wondering how to stream live? Watch this video tutorial for a simple explanation of how to broadcast live on your channel using any common broadcasting software on your computer, like OBS (Open Broadcast Studio), vMix, Wirecast, or others.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO BROADCAST LIVE (SIMPLE LIVESTREAM).mp4`,
    watchStatus: 0,
    duration: 194,
    tags: [TutorialType.LiveBroadcast],
  },
  {
    id: 18,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/HOW TO CREATE AND MANAGE CHANNELS.png`,
    title: 'HOW TO CREATE AND MANAGE CHANNELS',
    description: `This tutorial explains how to create and manage channels inside your account from the Channel’s section of your dashboard, including how to use the settings for each channel in your account, how to add streams from external sources to your network, and more.`,
    source: `${environment.staticCDNUrl}/video-tutorials/HOW TO CREATE AND MANAGE CHANNELS.mp4`,
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.Channels],
  },
  {
    id: 19,
    thumbnail: `${environment.staticCDNUrl}/video-tutorials/USING THE ANALYTICS FEATURE.png`,
    title: 'USING THE ANALYTICS FEATURE',
    description: `This tutorial gives a detailed overview of using the Analytics feature inside your channel to track viewer statistics for all aspects of your channel.`,
    source: `${environment.staticCDNUrl}/video-tutorials/USING THE ANALYTICS FEATURE.mp4`,
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.Analytics],
  },
  {
    id: 20,
    thumbnail: '/assets/img/app/imgpsh_fullsize.png',
    title: 'HOW TO IMPORT VIDEOS FROM YOUTUBE/VIMEO/DROPBOX/GOOGLE DRIVE',
    description: `Within this video tutorial we show you how to use the import tools to easily import videos from YouTube, Vimeo, Dropbox and/or Google Drive into your video library.`,
    source: '',
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.VideoLibrary],
  },
  {
    id: 21,
    thumbnail: '/assets/img/app/imgpsh_fullsize.png',
    title: 'WEBSITE BUILDER - HOW TO CREATE A NEW SITE',
    description: `Coming soon`,
    source: '',
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.WebsiteBuilder],
  },
  {
    id: 22,
    thumbnail: '/assets/img/app/imgpsh_fullsize.png',
    title: 'WEBSITE BUILDER - HOW TO CUSTOMIZE PAGES',
    description: `Coming soon`,
    source: '',
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.WebsiteBuilder],
  },
  {
    id: 23,
    thumbnail: '/assets/img/app/imgpsh_fullsize.png',
    title: 'WEBSITE BUILDER - HOW TO SETUP AND EDIT FORMS',
    description: `Coming soon`,
    source: '',
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.WebsiteBuilder],
  },
  {
    id: 24,
    thumbnail: '/assets/img/app/imgpsh_fullsize.png',
    title: 'WEBSITE BUILDER - HOW TO PUBLISH YOUR SITE',
    description: `Coming soon`,
    source: '',
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.WebsiteBuilder],
  },
  {
    id: 25,
    thumbnail: '/assets/img/app/imgpsh_fullsize.png',
    title: 'BROADCAST STUDIO',
    description: `
        a. BROADCAST STUDIO INTRODUCTION
        b. HOW TO INVITE AND MANAGE GUESTS
        c. HOW TO SYNC AND DISPLAY SOCIAL MEDIA COMMENTS
        d. HOW TO USE GRAPHICS OVERLAYS
      `,
    source: '',
    watchStatus: 0,
    duration: 500,
    tags: [TutorialType.LiveBroadcast],
  },
];
