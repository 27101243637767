import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '@app/auth/services';
import { CommonHelpers } from '@app/helpers';
import { Video } from '@app/shared/domain';
import { UploadChunkService, VideoService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { filter, first } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from '@env/environment';

@Component({
  selector: 'app-assign-trailer-clip',
  templateUrl: './assign-trailer-clip.component.html',
  styleUrls: ['./assign-trailer-clip.component.scss'],
})
export class AssignTrailerClipComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public files: NgxFileDropEntry[] = [];
  public dataSource: MatTableDataSource<Video> = new MatTableDataSource([]);
  public displayedColumns: string[] = ['thumbnail', 'title', 'description', 'duration', 'created', 'actions'];
  public params = {
    page: 1,
    pageSize: 20,
    total: 0,
    totalPage: 0,
    search: '',
    ordering: '-created',
    pageSizeOptions: [20, 50, 100, 150, 200],
  };
  public activeVideoId: string;
  private uploadSocket: WebSocketSubject<any>;
  constructor(
    private videoService: VideoService,
    public dialogRef: MatDialogRef<AssignTrailerClipComponent>,
    private notifier: NotifierService,
    public uploadChunkService: UploadChunkService,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.activeVideoId = data.activeVideoId;
    this.authService
      .getAccountInfo()
      .pipe(filter((s) => !!s))
      .subscribe((user) => {
        if (this.uploadSocket) {
          this.uploadSocket.complete();
        }
        const token = this.authService.getStoreToken();
        this.uploadSocket = webSocket({
          url: `${environment.wsUrl}/video-encode-status/${user.id}/?token-key=${token}`,
        });

        this.uploadSocket.subscribe(
          ({ message }) => {
            if (message) {
              this.getVideos();
            }
          },
          (err) => console.log(err),
          () => console.log('complete')
        );
      });
  }
  ngOnDestroy(): void {
    if (this.uploadSocket) {
      this.uploadSocket.complete();
    }
  }

  ngOnInit() {
    this.getVideos();
  }

  getVideos() {
    this.isLoading = true;
    this.params.total = 0;
    this.params.totalPage = 0;
    const { page, pageSize, search, ordering } = this.params;
    this.videoService
      .getVideos({
        page,
        page_size: pageSize,
        search,
        ordering,
      })
      .subscribe(
        (res) => {
          const { results, total } = res;
          this.params.total = total;
          this.dataSource = new MatTableDataSource(results);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      const videoExtensions = ['mp4', 'avi', 'mov', 'flv', '3gp', 'webm', 'mkv', 'mpg'];
      this.files = files;

      if (files.length > 10) {
        this.notifier.notify('warning', 'The max number of simulatenous uploads should be limited to 10');
        return;
      }
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const fileExtension = CommonHelpers.getFileExtension(file.name);
          if (videoExtensions.indexOf(fileExtension) === -1) {
            this.notifier.notify('warning', 'File is not supported');
            return;
          }

          if (file.size > 30000000 * 1024) {
            this.notifier.notify('warning', 'Video is too large');
            return;
          }
          const videoTitle = CommonHelpers.getFileName(file.name);

          const videoId = '';
          const item = {
            progress: 0,
            title: videoTitle,
            error: false,
          };

          this.uploadChunkService
            .getProgressUpload()
            .pipe(first())
            .subscribe((progresses) => {
              const newArr = [...progresses, item];
              this.uploadChunkService.setProgressUpload(newArr);
            });

          this.uploadFile(file, 0, {}, 50000 * 1024, fileExtension, videoTitle, item);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  uploadFile(
    file: File,
    offset: number,
    model: any,
    chunkSize: number = 50000 * 1024,
    ext: string,
    fileName: string,
    item: any
  ) {
    const nextChunk = offset + chunkSize + 1;
    const currentChunk = file.slice(offset, nextChunk);
    const uploadedChunk = offset + currentChunk.size;
    const end = uploadedChunk >= file.size;
    const x = item.progress;
    const fmData = new FormData();
    const data = {
      end,
      ext,
      offset,
      current_chunk: currentChunk.size,
      uuid: model?.uuid,
      encoding_profile: {
        name: '3000kbps',
        smil_title: '1080p',
        width: 1920,
        height: 1080,
        bit_rate: 3000,
      },
      filename: fileName,
    };

    fmData.append('file', currentChunk);
    fmData.append('options', JSON.stringify(data));
    this.uploadChunkService.uploadFile(fmData).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (item.progress < 100) {
              item.progress =
                x + Math.round((Math.round((event.loaded / event.total) * 100) * currentChunk.size) / file.size);
            }

            break;
          case HttpEventType.Response:
            if (nextChunk < file.size) {
              this.uploadFile(file, nextChunk, event.body, chunkSize, ext, fileName, item);
            } else {
              item.progress = 100;
              this.uploadChunkService
                .getProgressUpload()
                .pipe(first())
                .subscribe((progresses) => {
                  const items = progresses.filter((i) => i.title !== item.title);
                  this.uploadChunkService.setProgressUpload(items);
                });
              this.notifier.notify('success', event.body.message);
            }
        }
      },
      (err) => {
        this.notifier.notify('error', err);
      }
    );
  }

  public fileOver(event: any) {
    console.log(event);
  }

  public fileLeave(event: any) {
    console.log(event);
  }

  pageChanged(event: any) {
    this.params.pageSize = event.pageSize;
    this.params.page = event.pageIndex + 1;
    this.getVideos();
  }

  handleSearchVideos() {
    this.params.pageSize = 20;
    this.params.page = 1;
    this.getVideos();
  }

  handleSelect(video: Video) {
    this.dialogRef.close(video);
  }

  handleSortChange(event: any) {
    const { active, direction } = event;
    if (direction === 'asc') {
      this.params.ordering = active;
    } else {
      this.params.ordering = `-${active}`;
    }
    this.getVideos();
  }
}

export class AssignTrailerClipModel {}
