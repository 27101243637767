<h1 mat-dialog-title>Publish Website</h1>
<div *ngIf="!submitted" mat-dialog-content>
  <div class="info">Are you sure you want to publish this website?</div>
  <div class="info">(Publish your site now to a temporary link)</div>
</div>
<div *ngIf="!submitted" mat-dialog-actions class="action-btn">
  <button [disabled]="isLoading" mat-flat-button class="mat-small" color="grey" (click)="dialogRef.close(submitted)">
    Cancel
  </button>
  <button [loading]="isLoading" mat-flat-button class="mat-small" color="primary" (click)="handlePublish()">
    Publish
  </button>
</div>
<div *ngIf="submitted" class="pl-result">
  <!-- <hr class="solid" /> -->
  <h4>Publish to Custom Domain:</h4>
  <p>
    Please login to your domain provide account and set this link as a redirect under the DNS settings for your domain
  </p>
  <div class="link" id="publishLink">{{ getLink() }}</div>
  <div mat-dialog-actions class="action-btn">
    <button mat-flat-button class="mat-small" color="primary" (click)="dialogRef.close()">Help</button>
    <button mat-flat-button class="mat-small" color="primary" (click)="copyLink()">Copy Link</button>
  </div>
</div>
