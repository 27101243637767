import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Channel, ChannelService, ReportBugDialogComponent, WindowresizeService } from '@app/shared';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { MenuService } from '../components/menu/menu.service';
import { environment } from '../../../environments/environment';
import { filter, map } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cms-layout',
  templateUrl: './cms-layout.component.html',
  styleUrls: ['./cms-layout.component.scss'],
})
export class CmsLayoutComponent implements OnInit {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('psMainContent') psMainContent: PerfectScrollbarDirective;

  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public isStickyMenu = false;
  public lastScrollTop = 0;
  public showBackToTop = false;
  public toggleSearchBar = false;
  public scrolledContent: any;
  private defaultMenu: string; // declared for return default menu when window resized
  interval: any;
  channelId: string;
  is_blocked: boolean = false;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public route: ActivatedRoute,
    private menuService: MenuService,
    private channelService: ChannelService,
    private windowResizeService: WindowresizeService,
    private dialog: MatDialog,
    private notify: NotifierService,
    private snackBar: MatSnackBar
  ) {
    this.settings = this.appSettings.settings;
    if (environment.production) {
      // Embedded Chat
      const script = document.createElement('script');
      script.innerHTML = `var $zoho = $zoho || {};
        $zoho.salesiq = $zoho.salesiq || {
          widgetcode: '9507b03eb06967cb1e49c11b8c0fad4ea0374ab3658423287ed240b24236a158dfc2060e3cba99dc8bd26ff164d86899',
          values: {},
          ready: function () {},
        };
        var d = document;
        s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zsiqscript';
        s.defer = true;
        s.src = 'https://salesiq.zoho.com/widget';
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);`;
      document.head.appendChild(script);
    }
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        const { sidenavIsOpened = true } = route.data;
        if (!sidenavIsOpened) {
          if (this.sidenav) {
            this.sidenav.toggle();
          }
          this.settings.sidenavIsOpened = false;
        }
      });
  }

  ngOnInit() {
    this.channelService.getDropDownChannel();
    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;

    this.channelService
      .getActiveChannel()
      .pipe(filter((s) => !!s))
      .subscribe((channel) => {
        const { uuid: channelId } = channel;
        this.channelId = channelId;
        this.getChannelStatus();
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu === 'vertical')
      this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
    this.windowResizeService.setPerfectScroll(this.psMainContent);

    this.handleSnackBar();
  }

  public handleSnackBar() {
    this.interval = setInterval(() => {
      if (this.is_blocked === false) {
        this.getChannelStatus();
      }
    }, 50000);
  }

  public getChannelStatus() {
    if (this.channelId) {
      this.is_blocked = true;
      this.channelService.getChannelStatus(this.channelId).subscribe(
        (res) => {
          if (res.is_all_setup === true) {
            clearInterval(this.interval);
            this.snackBar.dismiss();
          } else {
            this.snackBar.open('Channel not setup yet', '', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
            });
          }
          this.is_blocked = false;
        },
        (err) => {
          this.notify.notify('error', err);
          this.is_blocked = false;
        }
      );
    }
  }

  public changeActiveChannel(channel: Channel) {
    this.channelService.changeActiveChannel(channel);
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(['/']);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme: any) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event: any) {
    this.scrolledContent = event.target;
    this.scrolledContent.scrollTop > 300 ? (this.showBackToTop = true) : (this.showBackToTop = false);
    if (this.settings.menu === 'horizontal') {
      if (this.settings.fixedHeader) {
        const currentScrollTop = this.scrolledContent.scrollTop > 56 ? this.scrolledContent.scrollTop : 0;
        currentScrollTop > this.lastScrollTop ? (this.isStickyMenu = true) : (this.isStickyMenu = false);
        this.lastScrollTop = currentScrollTop;
      } else {
        this.scrolledContent.scrollTop > 56 ? (this.isStickyMenu = true) : (this.isStickyMenu = false);
      }
    }
  }

  public scrollToTop() {
    const scrollDuration = 200;
    const scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
    const scrollInterval = setInterval(() => {
      if (this.scrolledContent.scrollTop !== 0) {
        this.scrolledContent.scrollBy(0, scrollStep);
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= 768) {
      this.scrolledContent.scrollTop = 0;
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical';
    } else {
      this.defaultMenu === 'horizontal' ? (this.settings.menu = 'horizontal') : (this.settings.menu = 'vertical');
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  openReportDialog() {
    const dialogRef = this.dialog.open(ReportBugDialogComponent, {
      width: '800px',
    });
  }
}
