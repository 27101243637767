import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private TOKEN_KEY = 'Bearer';
  private url = 'https://api.tvstartupcms.com/api/v1/clients/event-ticket/list/';
  constructor(private authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getStoreToken();
    if (token) request = this.addToken(request, token);
    return next.handle(request);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `${
          request.url === this.url ? `Tenant-Key ${localStorage.getItem('accessKey')}` : `${this.TOKEN_KEY} ${token}`
        }`,
      },
    });
  }
}
