import { Component, Input, OnInit } from '@angular/core';
import { FooterService } from './footer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  color: string;
  showFooter = true;
  year = new Date().getFullYear();

  constructor(private footerService: FooterService) {
    this.footerService.subject.subscribe((showFooter: boolean) => {
      this.showFooter = showFooter;
    });
  }

  ngOnInit() {}
}
