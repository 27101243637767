<app-spinner *ngIf="isLoading"></app-spinner>

<form [formGroup]="pricingForm" *ngIf="!isLoading">
  <div fxLayout="row wrap">
    <div [fxFlex]="100" fxFlex.md="40" fxFlex.gt-md="40" class="flex-p">
      <div class="label">Subscription Plan</div>
      <div class="sub-label">
        A subscription plan includes pricing and billing cycle information that defines the amount and recurring charge
        for a subscription.
      </div>
    </div>
    <div [fxFlex]="100" fxFlex.md="60" fxFlex.gt-md="60" class="flex-p">
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="subs_plan_uuid" placeholder="Choose plan">
          <mat-option *ngFor="let plan of subsPlans" [value]="plan.uuid">{{ plan.title }}</mat-option>
        </mat-select>
        <mat-error *ngIf="f.subs_plan_uuid.invalid && f.subs_plan_uuid.touched"
          >Subscription plan is required</mat-error
        >
      </mat-form-field>
    </div>
  </div>
  <div fxLayout="row wrap">
    <div [fxFlex]="100" fxFlex.md="40" fxFlex.gt-md="40" class="flex-p">
      <div class="label">Pay Per View Price</div>
      <div class="sub-label">Set a one-time purchase price to access this category.</div>
    </div>
    <div [fxFlex]="100" fxFlex.md="60" fxFlex.gt-md="60" class="flex-p">
      <mat-checkbox #abcd class="mb-16" color="primary" formControlName="enablePurchasePrice"
        >Enable Purchase Price</mat-checkbox
      >
      <mat-form-field appearance="outline" class="w-100 purchase-price mb-16">
        <input type="number" formControlName="price" matInput placeholder="Please input price" />
        <div matSuffix>USD</div>
        <mat-error *ngIf="f.price.invalid && f.price.touched">
          <ng-container *ngIf="f.price.errors.required; else minMaxTpl"> Price is required </ng-container>
          <ng-template #minMaxTpl>
            <ng-container *ngIf="f.price.errors.min">
              Please enter a value greater than {{ f.price.errors.min }}
            </ng-container>
            <ng-container *ngIf="f.price.errors.max">
              Please enter a value less than {{ f.price.errors.max }}
            </ng-container>
          </ng-template>
        </mat-error>
      </mat-form-field>
      <mat-checkbox class="mb-16" color="primary" formControlName="is_lifetime">Lifetime Access</mat-checkbox>
      <br />
      <mat-checkbox class="mb-16" color="primary" formControlName="isLimitedTimeAccess"
        >Limited Time Access</mat-checkbox
      >
      <mat-form-field appearance="outline" class="w-100">
        <mat-select formControlName="expires_in" placeholder="Choose limited time access">
          <mat-option [value]="'24 hours'">24 hours</mat-option>
          <mat-option [value]="'7 days'">7 days</mat-option>
          <mat-option [value]="'30 days'">30 days</mat-option>
        </mat-select>
        <mat-error *ngIf="f.expires_in.invalid && f.expires_in.touched"> Limited time access is required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <app-purchase-link-setup formGroupName="purchaseLinkInfo" [publishedSites]="publishedSites"></app-purchase-link-setup>
</form>
<div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end center" class="flex-p" fx>
  <button mat-flat-button color="grey" (click)="handleCancel()">Cancel</button>
  <button mat-flat-button color="primary" (click)="handleSave()">Save</button>
</div>
