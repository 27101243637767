import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { map } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private apiService: ApiService) {}

  getListInvoiceHistory(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/billing/invoice/histories/', params);
  }

  // refund(): Observable<any>{
  //   return this.apiService.post('/api/v1/billing/invoice/histories/');
  // }

  invoiceEmail(data: any): Observable<any> {
    return this.apiService.post('/api/v1/billing/invoice/details/', data);
  }

  exportInvoiceHistory(): Observable<any> {
    return this.apiService.download('/api/v1/billing/invoice/export/');
  }

  getListCouponCode(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/billing/offer/list/', params);
  }

  saveCouponCode(data: any): Observable<any> {
    return this.apiService.post('/api/v1/billing/offer/save-single/', data);
  }

  updateCouponCode(data: any): Observable<any> {
    return this.apiService.put('/api/v1/billing/offer/save-single/', data);
  }

  removeCouponCode(id: string): Observable<any> {
    return this.apiService.delete(`/api/v1/billing/offer/remove-single/${id}`);
  }

  getPpvEvents(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => (params = params.set(key, value)));
    return this.apiService.get(`/api/v1/clients/event-ticket/list/`, params);
  }

  getListTicket(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value && key !== 'not_expired') {
        params = params.set(key, value);
      }

      // Expired
      if (key === 'not_expired' && value) {
        params = params.set('event_dates__endswith__gt', new Date().toISOString());
      }
    });

    return this.apiService.get('/api/v1/billing/ticket/list/', params);
  }

  saveTicket(data: any): Observable<any> {
    return this.apiService.post('/api/v1/billing/ticket/save-single/', data);
  }

  updateTicket(data: any): Observable<any> {
    return this.apiService.put('/api/v1/billing/ticket/save-single/', data);
  }

  uploadTicketImage(data: any): Observable<any> {
    return this.apiService.put('/api/v1/billing/ticket/upload-image/', data);
  }

  uploadImage(data: FormData): Observable<any> {
    return this.apiService.post('/api/v1/material/raw-upload/', data);
  }
}
