import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonHelpers } from '@app/helpers';
import { NotSameFiled } from '@app/helpers/validators';
import { ChannelService } from '@app/shared/services';
import { SubscriberService } from '@app/shared/services/subscriber/subscriber.service';
import { SubscriptionService } from '@app/shared/services/subscription/subscription.service';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-upload-subscribers-dialog',
  templateUrl: './upload-subscribers-dialog.component.html',
  styleUrls: ['./upload-subscribers-dialog.component.scss'],
})
export class UploadSubscribersDialogComponent implements OnInit {
  public fieldForm: FormGroup;
  public fileName: string;
  public channel: string;
  public channelList$ = this.channelService.getChannelList();
  public plans: any[] = [];
  public isLoadingPlan: boolean;

  constructor(
    public dialogRef: MatDialogRef<UploadSubscribersDialogComponent>,
    private formBuilder: FormBuilder,
    private papa: Papa,
    private channelService: ChannelService,
    private subscriptionService: SubscriptionService,
    private subscriberService: SubscriberService,
    private notifier: NotifierService
  ) {}

  public subscriber: string[] = [];

  public data: any[];

  fields: any[] = [
    {
      value: 'username',
      display: 'Username',
    },
    {
      value: 'l_name',
      display: 'Last Name',
    },
    {
      value: 'f_name',
      display: 'First Name',
    },
    {
      value: 'username',
      display: 'Username',
    },
    {
      value: 'email',
      display: 'Email',
    },
    {
      value: 'website',
      display: 'Website',
    },
  ];

  ngOnInit(): void {
    this.buildForm();
  }

  getListSubscriptionPlan() {
    this.subscriptionService.getListSubscriptionPlan(this.channel).subscribe((res) => {
      this.plans = res;
    });
  }

  get f() {
    return this.fieldForm?.controls;
  }

  buildForm() {
    this.fieldForm = this.formBuilder.group(
      {
        field1: ['username', [Validators.required]],
        field2: ['f_name', [Validators.required]],
        field3: ['l_name', [Validators.required]],
        field4: ['email', [Validators.required]],
        field5: ['website', [Validators.required]],
        subs_plan_uuid: ['', [Validators.required]],
        channel_uuid: ['', [Validators.required]],
      },
      {
        validator: NotSameFiled(),
      }
    );
    this.fieldForm?.get('channel_uuid').valueChanges.subscribe((channelId) => {
      this.isLoadingPlan = true;
      this.subscriptionService.getListSubscriptionPlan(channelId).subscribe((res) => {
        this.isLoadingPlan = false;
        this.plans = res;
      });
    });
  }

  public dropped(files: NgxFileDropEntry[]) {
    const fileTypes = ['application/vnd.ms-excel', 'text/csv'];
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.fileName = file.name;
          if (fileTypes.indexOf(file.type) === -1) {
            return;
          }
          this.papa.parse(file, {
            complete: (result) => {
              let data = result.data;
              if (data && data.length) {
                data = data.slice(1);
                this.subscriber = data[0];
                this.data = data;
              }
            },
          });
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  handleImportCSV() {
    if (this.fieldForm.invalid) {
      CommonHelpers.validateAllFormFields(this.fieldForm);
      return;
    }
    const data = [];

    const { field1, field2, field3, field4, field5, channel_uuid, subs_plan_uuid } = this.fieldForm.value;
    let plan = this.plans.find((i: any) => i.uuid === subs_plan_uuid);
    let date_subscribed;
    let valid_to;
    if (plan) {
      if (plan.trial_in) {
        const arr = plan.trial_in.split(' ');
        const date = moment(new Date()).add(arr[0], arr[1]).format('YYYY-MM-DD');
        date_subscribed = moment(new Date()).format('YYYY-MM-DD');
        valid_to = date;
      } else {
        const arr = plan.expire_in.split(' ');
        const date = moment(new Date()).add(arr[0], arr[1]).format('YYYY-MM-DD');
        date_subscribed = moment(new Date()).format('YYYY-MM-DD');
        valid_to = date;
      }
    }

    for (const item of this.data) {
      if (item.length > 1) {
        data.push({
          subscriber: {
            [field1]: item[0],
            [field2]: item[1],
            [field3]: item[2],
            [field4]: item[3],
            [field5]: item[4],
          },
          subscriptions: [
            {
              channel_uuid,
              subs_plan_uuid,
              date_subscribed,
              valid_to,
            },
          ],
        });
      }
    }

    this.subscriberService.saveSubscribers(data).subscribe(
      (res) => {
        this.notifier.notify('success', res.message);
        this.dialogRef.close(true);
      },
      (err) => {
        this.notifier.notify('error', err);
      }
    );
  }

  handleCancel() {
    this.dialogRef.close(false);
  }
}
