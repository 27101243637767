import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';
import { Channel } from '@app/shared/domain';
import { ChannelService } from '@app/shared/services';
import { NotifierService } from 'angular-notifier';
import { EditChannelDialogComponent } from '..';

@Component({
  selector: 'app-external-channel-dialog',
  templateUrl: './external-channel-dialog.component.html',
  styleUrls: ['./external-channel-dialog.component.scss'],
})
export class ExternalChannelDialogComponent implements OnInit {
  public channel: Channel;
  public activeChannel$ = this.channelService.getActiveChannel();
  public channelForm: FormGroup;
  public isLoading: boolean;
  channelThumbnail: any;
  file: File;

  channelImage: string;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EditChannelDialogComponent>,
    private notifier: NotifierService,
    private channelService: ChannelService,
    @Inject(MAT_DIALOG_DATA) public data: ChannelExternalDialogModel
  ) {
    if (data && data.channel) {
      this.channel = data.channel;
      this.channelThumbnail = data.channel.image;
    }
  }

  ngOnInit() {
    this.buildForm();
  }

  get f() {
    return this.channelForm.controls;
  }

  buildForm() {
    this.channelForm = this.formBuilder.group({
      title: [this.channel?.title, [Validators.required, NoWhitespaceValidator()]],
      description: [this.channel?.description, [Validators.required, NoWhitespaceValidator()]],
      streamUrl: [this.channel?.stream_url, [Validators.required, NoWhitespaceValidator()]],
      isVisible: [false],
    });
  }

  handleUpdate(): void {
    if (this.channelForm.invalid) {
      CommonHelpers.validateAllFormFields(this.channelForm);
      return;
    }
    this.isLoading = true;
    const { title, description, streamUrl, isVisible } = this.channelForm.value;

    const slug = CommonHelpers.createSlug(title);

    const fmData = new FormData();
    fmData.append('title', title);
    fmData.append('description', description);
    fmData.append('slug', slug);
    fmData.append('stream_url', streamUrl);
    fmData.append('stream_mode', 'EXTERNAL');
    fmData.append('visible', isVisible);
    fmData.append('active', 'true');
    if (this.file && this.file.size) {
      fmData.append('image', this.file);
    }
    if (this.channel && this.channel.uuid) {
      this.channelService.updateChannel(fmData, this.channel.uuid).subscribe(
        (res) => {
          this.dialogRef.close(true);
        },
        (error) => {
          this.notifier.notify('error', 'Something went wrong');
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    } else {
      this.channelService.createChannel(fmData).subscribe(
        (res) => {
          this.dialogRef.close(true);
        },
        (error) => {
          this.notifier.notify('error', 'Something went wrong');
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }

  handleUploadChannelThumbnail(files: FileList) {
    const file = files[0];
    this.file = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      this.channelThumbnail = event.target.result;
    };
  }

  doToggleVisible(e: MatSlideToggleChange) {
    this.channelForm.get('isVisible').setValue(e.checked);
  }

  ngOnDestroy() {}
}

export class ChannelExternalDialogModel {
  constructor(public channel?: Channel) {}
}
