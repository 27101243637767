import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriberService {
  constructor(private apiService: ApiService) {}

  getListSubscribers(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/subscriber/${channelId}/list/`);
  }

  saveSubscriber(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/subscriber/save-single/`, data);
  }

  saveSubscribers(data: any): Observable<any> {
    return this.apiService.post(`/api/v1/subscriber/save-bulk/`, data);
  }

  updateSubscriberProfile(data: any): Observable<any> {
    return this.apiService.put(`/api/v1/subscriber/update-profile/`, data);
  }

  removeSubscriber(uuid: string): Observable<any> {
    return this.apiService.delete(`/api/v1/subscriber/remove-single/${uuid}`);
  }

  actionPassword(data: any, type: number): Observable<any> {
    return this.apiService.put(`/api/v1/subscriber/password/?action-type=${type}`, data);
  }

  exportSubscribers(channelId: string) {
    return this.apiService.download(`/api/v1/subscriber/${channelId}/export/`);
  }

  unsubscribe(subscriptionIds: string[]) {
    return this.apiService.post(`/api/v1/subscriber/unsubscribe/`, {
      uuids: subscriptionIds,
    });
  }

  unsubscribeMediaAccess(subscriptionId: string) {
    return this.apiService.post(`/api/v1/subscriber/unsubscribe-media-access/`, {
      uuid: subscriptionId,
    });
  }

  subscriberImportFromStripe() {
    return this.apiService.get(`/api/v1/subscriber/import-from-stripe/`);
  }
}
