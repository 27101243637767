import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Template } from '@app/shared/domain/template.domain';
import { ProjectService } from '@app/shared/services';

@Component({
  selector: 'app-project',
  templateUrl: './active-project.component.html',
  styleUrls: ['./active-project.component.scss'],
})
export class ActiveProjectComponent implements OnInit {
  @Input('buttonClass') buttonClass = 'mat-flat-button';
  @Input('color') color = '';
  projectList: { name: string; uuid: string; description?: string }[] = null;
  public projectList$ = this.projectService.getProjectList();
  public activeProject$ = this.projectService.getActiveProject();
  public projectLoading$ = this.projectService.getIsLoading();

  constructor(private projectService: ProjectService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.projectList$.subscribe((res) => {
      this.projectList = res.results ? res.results : [];
    });
    this.projectService.getNewProjects().subscribe((res) => {
      if (res) {
        this.projectList = [...this.projectList, res];
        this.projectService.setActiveProject(res);
      }
    });
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  public changeActiveProject(project: { name: string; uuid: string; description?: string }) {
    this.projectService.setActiveProject(project);
  }
}
