<h1 mat-dialog-title>Create New Tag</h1>
<div mat-dialog-content>
  <form [formGroup]="tagForm">
    <div class="tag-fields" fxLayout="column">
      <mat-label class="text-weight-bold">Name:</mat-label>
      <mat-form-field appearance="outline" class="w-100">
        <input type="text" formControlName="name" matInput placeholder="Please input name" />
        <mat-error *ngIf="f.name.invalid && f.name.touched">Name is required</mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button class="mat-small" [style.margin-left]="'auto'" color="primary" (click)="handleCreate()">
    Create
  </button>
</div>
