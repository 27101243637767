import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '@env/environment';
import { NotifierService } from 'angular-notifier';
import { TemplateService } from '../../services/template/template.service';
import { TemplateSettings } from '../../domain/template.domain';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class GeneralSettingsComponent implements OnInit {
  public generalForm: FormGroup;
  previewFavicon: string;
  loadingFavicon: boolean;

  @Input() isPublished: boolean;
  @Input() templateId: string;
  @Input() siteName: string;
  @Input() supportEmail: string;
  @Input() settings: TemplateSettings;
  @Output() generalSettingsChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    const { videoStyle = 'standard', showContinueWatching = true } = this.settings?.generalSettings || {};
    this.generalForm = this.formBuilder.group({
      videoStyle: [videoStyle],
      showContinueWatching: [showContinueWatching],
      siteName: this.siteName,
      supportEmail: this.supportEmail,
    });
    this.generalForm.valueChanges.subscribe((values) => {
      this.generalSettingsChange.emit(values);
    });

    this.previewFavicon = `${environment.websiteUrl}/clients/${this.templateId}/favicon.png?${new Date().getTime()}`;
  }

  get f() {
    return this.generalForm?.controls;
  }

  handleChange(files: FileList) {
    if (files && files.length) {
      if (files.length === 0) return;
      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.notifier.notify('warning', 'Only images are supported');
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      const data = new FormData();
      data.append('file', files[0]);
      if (!this.templateId) {
        return;
      }
      this.loadingFavicon = true;
      this.templateService.uploadFavicon(this.templateId, data).subscribe((_) => {
        this.loadingFavicon = false;
        this.previewFavicon = `${environment.websiteUrl}/clients/${
          this.templateId
        }/favicon.png?${new Date().getTime()}`;
      });
    }
  }

  errorFaviconHandler() {
    this.previewFavicon = '';
  }
}
