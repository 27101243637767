<mat-card fxFlex>
  <mat-card-header>{{ title | uppercase }}</mat-card-header>
  <mat-card-content>
    <div class="flex-p-y">
      <app-stream-play [streamId]="streamId" [playOrder]="'hls'" (setStreamStatus)="setStreamStatus($event)">
      </app-stream-play>
    </div>
    <div fxLayout="row wrap">
      <div [fxFlex]="100" fxFlex.md="50" fxFlex.gt-md="50" class="flex-p">
        <div class="text-weight-bold">Playlist:</div>
        <mat-radio-group>
          <mat-radio-button
            [class]="!isChannelOnline && streamId && playlistName ? 'mat-success' : 'mat-danger'"
            labelPosition="before"
            [checked]="true"
            >{{ !isChannelOnline && streamId && playlistName ? 'ON AIR' : 'OFF AIR' }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div [fxFlex]="100" fxFlex.md="50" fxFlex.gt-md="50" class="flex-p">
        <div class="text-weight-bold">Live Broadcast:</div>
        <mat-radio-group>
          <mat-radio-button
            [class]="!isChannelOnline ? 'mat-danger' : 'mat-success'"
            labelPosition="before"
            [checked]="true"
            >{{ !isChannelOnline ? 'OFF AIR' : 'ON AIR' }}</mat-radio-button
          >
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="row wrap" *ngIf="!isChannelOnline">
      <div [fxFlex]="100" fxFlex.md="50" fxFlex.gt-md="50" class="flex-p" *ngIf="playlistName">
        <div class="text-weight-bold">Now Playing:</div>
        <div>Playlist Name: {{ playlistName }}</div>
        <div>Video Name: {{ videoName }}</div>
      </div>
      <div [fxFlex]="100" fxFlex.md="50" fxFlex.gt-md="50" class="flex-p">
        <div class="text-weight-bold">Live Recording:</div>
        <mat-radio-group>
          <mat-radio-button class="mat-danger" labelPosition="before" [checked]="true">OFF</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div fxLayout="row wrap" *ngIf="!isChannelOnline">
      <div [fxFlex]="100" fxFlex.md="50" fxFlex.gt-md="50" class="flex-p" *ngIf="playlistName">
        <div class="text-weight-bold">Up Next in ({{ upNextTime }})</div>
        <div>Video Name: {{ upNextVideo }}</div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
