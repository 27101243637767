import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, HostListener, Inject } from '@angular/core';
import { TemplateService } from '../../services/template/template.service';
import { NotifierService } from 'angular-notifier';
import { environment } from '@env/environment';

@Component({
  selector: 'app-publish-dialog',
  templateUrl: './publish-dialog.component.html',
  styleUrls: ['./publish-dialog.component.scss'],
})
export class PublishDialogComponent {
  public submitted = false;
  public isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<PublishDialogComponent>,
    public templateService: TemplateService,
    private notifier: NotifierService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  handlePublish(): void {
    this.isLoading = true;
    this.templateService.publishWebsite(this.data).subscribe(
      () => {
        this.submitted = true;
        this.isLoading = false;
      },
      (err) => {
        this.notifier.notify('error', err);
        this.isLoading = false;
        this.dialogRef.close();
      }
    );
  }

  getLink() {
    return `${environment.objectStorageUrl}/${this.data.uuid}/index.html`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  copyLink() {
    const copyText = document.getElementById('publishLink').textContent;
    navigator.clipboard.writeText(copyText);
  }

  @HostListener('document:click', ['$event'])
  clickedOut(e: any) {
    if (this.submitted && !e.target.closest('mat-dialog-container')) {
      this.dialogRef.close(true);
    }
  }
}
