import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Playlist } from '../../domain';
import { map } from 'lodash';
import { distinctUntilChanged } from 'rxjs/operators';
import { CommonHelpers } from '@app/helpers';

@Injectable({
  providedIn: 'root',
})
export class VideoPlaylistService {
  private readonly selectedPlaylist$: BehaviorSubject<Playlist> = new BehaviorSubject<Playlist>(null);
  private readonly changeOrder$: Subject<Playlist> = new Subject<Playlist>();

  constructor(private apiService: ApiService) {}

  setSelectedPlaylist(selected: Playlist) {
    // if (selected?.videos && selected?.videos_order) {
    //   selected.videos = CommonHelpers.mapOrder(selected.videos, selected.videos_order, 'uuid');
    // }
    this.selectedPlaylist$.next(selected);
  }

  getSelectedPlaylist() {
    return this.selectedPlaylist$.asObservable().pipe(distinctUntilChanged());
  }

  setChangeOrder(playlist: Playlist) {
    this.changeOrder$.next(playlist);
  }

  getChangeOrder() {
    return this.changeOrder$.asObservable().pipe();
  }

  getPlaylists(data: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/playlists/', params);
  }

  updatePlaylist(playlist: Playlist, playlistId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/playlists/${playlistId}/`, playlist);
  }

  updateStartDatePlaylist(playlist: Playlist, playlistId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/playlists/${playlistId}/change-start-date/`, playlist);
  }

  deletePlaylist(playlistId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/playlists/${playlistId}/`);
  }

  createPlaylist(playlist: Playlist): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/`, playlist);
  }

  addVideo(playlistId: string, videoId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/add-video/${playlistId}/${videoId}/`);
  }

  removeVideo(playlistId: string, videoId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/remove-video/${playlistId}/${videoId}/`);
  }

  removeVideoInPlaylist(videoPlaylistId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/remove-playlist-video/${videoPlaylistId}/`);
  }

  changeOrder(playlist: any, playlistId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/${playlistId}/change-order/`, playlist);
  }

  clonePlaylist(playlistId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/${playlistId}/clone-playlist/`);
  }

  addAudio(playlistId: string, audioId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/add-audio/${playlistId}/${audioId}/`);
  }

  removeAudio(playlistId: string, audioId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/remove-audio/${playlistId}/${audioId}/`);
  }

  getPlaylistById(playlistId: string): Observable<any> {
    return this.apiService.get(`/api/v1/playlists/${playlistId}/`);
  }

  setManuallyStart(playlistId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/manually-start/${playlistId}/`);
  }

  getClientPlaylistById(playlistId: string): Observable<any> {
    return this.apiService.get(`/api/v1/clients/playlists/${playlistId}/`);
  }

  mergeVideos(playlistId: string): Observable<any> {
    return this.apiService.post(`/api/v1/playlists/merge-videos/${playlistId}/`);
  }
}
