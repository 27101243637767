import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';

interface ChangeSubscriptionPayload {
  action: string;
  data: {
    plan_code: string;
    addons: any[];
    coupon_code: string;
    end_trial_period: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class ZohoService {
  constructor(private apiService: ApiService) {}

  verifyFeature(feature: string): Observable<any> {
    return this.apiService.get(`/api/v1/feature/verify?key=${feature}`);
  }

  changeSubscription(data: ChangeSubscriptionPayload): Observable<any> {
    return this.apiService.put('/api/v1/zoho/subscription/save/', data);
  }

  getListAddons(plan: string): Observable<any> {
    return this.apiService.get(`/api/v1/zoho/addon/list?plan_code=${plan}`);
  }

  getPlan(plan: string): Observable<any> {
    return this.apiService.get(`/api/v1/zoho/plan/list?plan_code=${plan}`);
  }
}
