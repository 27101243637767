import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { CmsLayoutComponent } from './cms-layout/cms-layout.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './components/messages/messages.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { GuestLayoutComponent } from './guest-layout/guest-layout.component';
import { PackageExpiryStripComponent } from './components/package-expiry-strip/package-expiry-strip.component';

const COMPONENTS = [
  AuthLayoutComponent,
  CmsLayoutComponent,
  VerticalMenuComponent,
  MessagesComponent,
  SidenavComponent,
  UserMenuComponent,
  FooterComponent,
  GuestLayoutComponent,
  PackageExpiryStripComponent,
];

@NgModule({
  imports: [SharedModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class LayoutsModule {}
