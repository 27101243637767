import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonHelpers } from '@app/helpers';
import { NoWhitespaceValidator } from '@app/helpers/validators';

@Component({
  selector: 'app-create-tag-dialog',
  templateUrl: './create-tag-dialog.component.html',
  styleUrls: ['./create-tag-dialog.component.scss'],
})
export class CreateTagDialogComponent implements OnInit {
  public tagForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateTagDialogModel
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  get f() {
    return this.tagForm?.controls;
  }

  buildForm() {
    this.tagForm = this.formBuilder.group({
      name: ['', [Validators.required, NoWhitespaceValidator()]],
    });
  }

  handleCreate(): void {
    if (this.tagForm.invalid) {
      CommonHelpers.validateAllFormFields(this.tagForm);
      return;
    }
    const { name } = this.tagForm.value;
    this.dialogRef.close(name);
  }
}

export class CreateTagDialogModel {
  constructor() {}
}
