import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonLoadingDirective } from './directives/mat-button-loading.directive';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { PipesModule } from './pipes/pipes.module';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { FileSaverModule } from 'ngx-filesaver';
import { ColorPickerModule } from 'ngx-color-picker';
import { TreeModule } from '@circlon/angular-tree-component';
import {
  SpinnerComponent,
  EmptyComponent,
  ConfirmDialogComponent,
  ActiveChannelComponent,
  AddingAdditionalChannelDialogComponent,
  AdditionalChannelRequestDialogComponent,
  CreatePlaylistDialogComponent,
  ClipSettingsDialogComponent,
  CreateCategoryDialogComponent,
  CreateChannelDialogComponent,
  AssignTrailerClipComponent,
  VideojsPlayerComponent,
  EditChannelDialogComponent,
  AccountSettingDialogComponent,
  CreateTagDialogComponent,
  PreviewVideoComponent,
  ListCategoryDialogComponent,
  EditThumbnailDialogComponent,
  PreviewAudioComponent,
  PreviewChannelDialogComponent,
  RestreamAccountEditDialogComponent,
  UploadSubscribersDialogComponent,
  SubscriptionPlanDialogComponent,
  TutorialListMatDialogComponent,
  NoPaymentGatewayComponent,
  BroadcastStudioSettingDialogComponent,
  AddRtmpSourceDialogComponent,
  InviteGuestDialogComponent,
  BroadcastStudioEditNameDialogComponent,
  BroadcastStudioEditAvatarDialogComponent,
  SaveChangeDialogComponent,
  DuplicateVideoDialogComponent,
  ExternalChannelDialogComponent,
  VodPricingDialogComponent,
  ChannelEmbedCodeDialogComponent,
  UpgradePlanDialogComponent,
  PurchaseLinkSetupComponent,
  ConnectSocialMediaDialogComponent,
  NetworkConnectionComponent,
  NgMarqueeComponent,
  StreamPlayComponent,
  TutorialButtonComponent,
  ReportBugDialogComponent,
  ActiveProjectComponent,
} from './components';
import { PreventDoubleClickDirective } from './directives/prevent-click/prevent-double-click.directive';
import { PublishDialogComponent } from './components/publish-dialog/publish-dialog.component';
import { TemplateListComponent } from './components/template-list/template-list.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { CreatePageSettingsDialogComponent } from './components/create-page-settings-dialog/create-page-settings-dialog.component';
import { PageSettingsComponent } from './components/page-settings/page-settings.component';
import { EmailGuestDialogComponent } from './components/email-guest-dialog/email-guest-dialog.component';
import { StreamPlayerComponent } from './components/stream-player/stream-player.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

const COMPONENTS = [
  ActiveChannelComponent,
  AddingAdditionalChannelDialogComponent,
  AdditionalChannelRequestDialogComponent,
  SpinnerComponent,
  EmptyComponent,
  ConfirmDialogComponent,
  CreatePlaylistDialogComponent,
  ClipSettingsDialogComponent,
  CreateCategoryDialogComponent,
  CreateChannelDialogComponent,
  AssignTrailerClipComponent,
  VideojsPlayerComponent,
  EditChannelDialogComponent,
  AccountSettingDialogComponent,
  CreateTagDialogComponent,
  PreviewVideoComponent,
  ListCategoryDialogComponent,
  EditThumbnailDialogComponent,
  PreviewAudioComponent,
  PreviewChannelDialogComponent,
  UploadSubscribersDialogComponent,
  TutorialListMatDialogComponent,
  SubscriptionPlanDialogComponent,
  NoPaymentGatewayComponent,
  BroadcastStudioSettingDialogComponent,
  AddRtmpSourceDialogComponent,
  InviteGuestDialogComponent,
  BroadcastStudioEditNameDialogComponent,
  BroadcastStudioEditAvatarDialogComponent,
  SaveChangeDialogComponent,
  DuplicateVideoDialogComponent,
  ExternalChannelDialogComponent,
  VodPricingDialogComponent,
  ChannelEmbedCodeDialogComponent,
  UpgradePlanDialogComponent,
  RestreamAccountEditDialogComponent,
  PurchaseLinkSetupComponent,
  PublishDialogComponent,
  TemplateListComponent,
  GeneralSettingsComponent,
  PageSettingsComponent,
  CreatePageSettingsDialogComponent,
  ConnectSocialMediaDialogComponent,
  EmailGuestDialogComponent,
  NetworkConnectionComponent,
  StreamPlayerComponent,
  NgMarqueeComponent,
  StreamPlayerComponent,
  StreamPlayComponent,
  TutorialButtonComponent,
  ReportBugDialogComponent,
  ActiveProjectComponent,
];

const DIRECTIVES = [MatButtonLoadingDirective, PreventDoubleClickDirective];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    NgxFileDropModule,
    PerfectScrollbarModule,
    PipesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    InfiniteScrollModule,
    DragDropModule,
    ClipboardModule,
    NgxAudioPlayerModule,
    FileSaverModule,
    ColorPickerModule,
    TreeModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    NgxFileDropModule,
    PerfectScrollbarModule,
    PipesModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    InfiniteScrollModule,
    DragDropModule,
    ClipboardModule,
    NgxAudioPlayerModule,
    FileSaverModule,
    ColorPickerModule,
    TreeModule,
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
