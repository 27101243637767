import { Package } from './package.domain';
import { SubscriptionPlan } from './subscription-plan.domain';

export interface Channel {
  uuid?: string;
  title: string;
  slug: string;
  channel_profile: string;
  image?: any;
  default: boolean;
  active: boolean;
  featured?: boolean;
  package: Package;
  subscription_plan: SubscriptionPlan;
  stream_token: string;
  stream_key: string;
  stream_url: string;
  stream_mode: 'EXTERNAL' | 'INTERNAL';
  reset_stream_key: boolean;
  description: string;
  timezone: string;
  stream_hls_url?: string;
  status?: string;
  selected?: boolean;
  thumbnail?: any;
  stream_username: string;
  stream_password: string;
  player_url?: string;
  recording_url?: string;
  ant_socket_url?: string;
  stream_hls_url_base?: string;
}

export interface ChannelDefault {
  stream_key: string;
  stream_url: string;
  channel_profile: string;
}

export interface ChannelTimezone {
  key: string;
  value: string;
}

export enum StreamType {
  PLAYLIST,
  BROADCASTING,
}
